@use '@angular/material' as mat;
@include mat.all-component-typographies();
@include mat.core();

/* You can add global styles to this file, and also import other style files */
// Import functions, variables, and mixins needed by other Bootstrap files
@import '../../node_modules/bootstrap/scss/functions';

@import 'variables';

@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';

@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/utilities/api';
@import '../../node_modules/bootstrap/scss/grid';

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font:
		100 14px / 20px 'Nunito Sans',
		sans-serif;
	background: #f1e8e5;
	overflow-x: hidden;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
	font:
		100 14px / 20px 'Nunito Sans',
		sans-serif;
}

.clickable {
	cursor: pointer;
}

.mat-mdc-dialog-container {
	position: relative;

	button.close-button {
		position: absolute;
		right: 5px;
		top: 5px;
	}
}

@include mat.all-component-themes($my-theme);

.mat-mdc-tooltip {
	white-space: pre-line;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 10px;
}

*::-webkit-scrollbar-thumb {
	background-color: gray;
	border-radius: 10px;
}

@include media-breakpoint-down(xl) {
	.container-fluid {
		max-width: 95%;
	}
}
